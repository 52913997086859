<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 auth-con">
                <div class="logo-site">
                     <img src="/theme/img/logo-dark.svg">
                 </div>
               <div class="authbox">
                   <h3>Sign Up</h3>
                   <Form  id="signup" method="post" @submit="signupHandle" v-if="!registerOk">
                       <div class="mb-3">
                            <label for="useremail" class="form-label">Email address</label>
                            <Field ref="useremail"  type="email" class="form-control" id="useremail" name="useremail" placeholder="name@example.com" v-model="model.useremail" :rules="validateEmail">
                            </Field>
                            <ErrorMessage name="useremail" />
                        </div>
                        <div class="mb-3">
                            <label for="userpassword" class="form-label">Password</label>
                            <Field  type="password" class="form-control" id="userpassword" name="userpassword" placeholder="password" v-model="model.userpassword" :rules="validatePwd">
                            </Field>
                             <ErrorMessage name="userpassword" />
                        </div>
                        <div class="mb-3">
                            <p>{{model.status}}</p>
                        </div>
                        <div class="mb-3">
                            <button class="btn" type="submit">Register</button>
                        </div>
                        <div class="mb-3">
                            <a class="auth-nav" href="/auth/login"> Already have an account?</a>
                        </div>
                        <div class="mb-3">
                            <a class="auth-nav" href="/"> Skip</a>
                        </div>
                   </Form >
                   <div class=""  v-else v-html="model.status">
                   </div>
               </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, Field ,ErrorMessage} from 'vee-validate';
import axios from 'axios'
export default {
    components: { Form, Field, ErrorMessage},
    data () {
        return {
            registerOk:false,
            model: {
                useremail: '',
                userpassword: '',
                status:''
            }
        }
    },
    mounted() {
       // if(this.$refs.useremail) this.$refs.useremail.$el.focus()
    },
    methods: {
        signupHandle(values){
            var self=this;
            axios.post('/api/auth/register', {
                email:values.useremail,
                password: values.userpassword
        }).then(response => {
             self.registerOk = true
            self.model.status = `<h1>Your Account Created</h1> <p>${response.data.message}</p><a href="/auth/login" class="btn">Sign In</a>`
           
        }).catch(error=>{
            self.model.status = error.response.data.message
            setTimeout(() => { self.model.status ="" }, 3000);
        })},
        validateEmail(value) {
            if (!value) {   return 'Email is required';}
            var regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!regex.test(value)) {   return 'Invalid email';}
            return true;
        },
        validatePwd(value){
            if (!value) {   return 'Password is required';}
            if (value.length<6) {   return 'Password min 6 chars required';}
            if (value.length>42) {   return 'Password maximum length 42 chars';}
            var regex =  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,34}$/;
            if (!regex.test(value)) {   return 'Password min length 6 has at least one number and one special character (!@#$%^&*)';}
            return true;
        } 
    }
}
</script>